import React,{Component} from "react"
import Logo from './logo'
import {Link} from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookMessenger,faSnapchatGhost,faInstagramSquare} from '@fortawesome/free-brands-svg-icons'
import { faSms} from '@fortawesome/free-solid-svg-icons'

// Documentation: http://cms.devoffice.com/coding-dev/rd-navbar/documentation/index.php


class Header extends Component {

  constructor(props){
    super(props);
    this.state = { width: 0, height: 0, opacity: 0, scrollY: 0, fixed: false, sticky: false, panelActive: false, menuActive:false, navBarClasses: Array('rd-navbar','rd-navbar-classic','rd-navbar-original')};

    this.togglePanel = this.togglePanel.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount(){
    this.setState({opacity: 1});
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
    window.removeEventListener('scroll', this.handleScroll);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });

    if(window.innerWidth < 992){
      this.setState({fixed:true})
    }
    else{
      this.setState({fixed:false})
    }
  }

  handleScroll(event){
    this.setState({scrollY: window.pageYOffset});

    if(this.state.scrollY > 46 && this.state.sticky == false){
      this.setState({sticky: true});
    }else if(this.state.scrollY <= 46 && this.state.sticky == true){
      this.setState({sticky: false});
    }
  }

  calculateNavBarClasses(){
    let classes = this.state.navBarClasses.toString().replace(/,/g,' ');

    if(this.state.sticky){
      classes += " rd-navbar--is-stuck"
    }

    if(this.state.fixed){
      classes += " rd-navbar-fixed"
    }else{
      classes += " rd-navbar-static"
    }

    return classes
  }

  toggleMenu(){
    this.setState({panelActive: false, menuActive: !this.state.menuActive});
  }

  togglePanel(){
    this.setState({menuActive: false, panelActive: !this.state.panelActive});
  }

  render(){
    const navBarClasses = this.calculateNavBarClasses()

    return (
      <>
      <header className="section page-header">
        {/*RD Navbar*/}
        <div className="rd-navbar-wrap">
          <nav className={navBarClasses}
               style={{opacity: this.state.opacity,borderTop: '8px solid #ffd613'}}
               data-layout="rd-navbar-fixed" data-sm-layout="rd-navbar-fixed"
               data-md-layout="rd-navbar-fixed" data-md-device-layout="rd-navbar-fixed" data-lg-layout="rd-navbar-static"
               data-lg-device-layout="rd-navbar-static" data-xl-layout="rd-navbar-static"
               data-xl-device-layout="rd-navbar-static" data-lg-stick-up-offset="46px" data-xl-stick-up-offset="46px"
               data-xxl-stick-up-offset="46px" data-lg-stick-up="true" data-xl-stick-up="true" data-xxl-stick-up="true">
            {/*<div onClick={this.toggleMenu} className={"rd-navbar-collapse-toggle rd-navbar-fixed-element-1" + (this.state.menuActive?" active":"")} data-rd-navbar-toggle=".rd-navbar-collapse"><span/></div>*/}
            {/*<div className={"rd-navbar-aside-outer rd-navbar-collapse bg-gray-900" + (this.state.menuActive?" active":"")}>
              <div className="rd-navbar-aside">
                <div className="rd-navbar-aside-left">
                  <ul className="list-inline list-inline-lg">
                    <li>
                      <div className="unit unit-spacing-sm align-items-center">
                        <div className="unit-left"><span className="icon icon-md text-middle fa-phone text-primary"/>
                        </div>
                        <div className="unit-body header-phone-wrap">
                          Få tips tilsendt: <a className="header-phone" href="tel:#">800-2345-6789</a>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon style={{color:'#ffd613'}} icon={faFacebookMessenger}/>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon style={{color:'#ffd613'}} icon={faSnapchatGhost}/>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon style={{color:'#ffd613'}} icon={faInstagramSquare}/>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon style={{color:'#ffd613'}} icon={faSms}/>
                        </div>
                      </div>
                    </li>
                    <li className="header-hours">Varsel om gratis ting, velkomstgaver og velkomsttilbud</li>
                  </ul>
                </div>
                <div className="rd-navbar-aside-right" style={{marginTop: '10px'}}>
                  <div className="group-sm" style={{marginBottom: '0'}}>
                    <a className="button button-sm button-secondary" href="#">Sign In</a>
                    <a className="button button-sm button-primary-2" href="#">🎁 Motta &raquo;</a></div>
                </div>
              </div>
            </div>*/}
            <div className="rd-navbar-main-outer">
              <div className="rd-navbar-main">
                {/*RD Navbar Panel*/}
                <div className="rd-navbar-panel">
                  {/*RD Navbar Toggle*/}
                  <button aria-label="Meny" onClick={this.togglePanel} className={"rd-navbar-toggle" + (this.state.panelActive?" active":"")} data-rd-navbar-toggle=".rd-navbar-nav-wrap"><span/></button>
                  {/*RD Navbar Brand*/}
                  <Logo/>
                  {/*<button>Send til en venn</button>*/}
                </div>
                <div className="rd-navbar-main-element">
                  <div className={"rd-navbar-nav-wrap" + (this.state.panelActive?" active":"")}>
                    <ul className="rd-navbar-nav">
                      <li className={this.props.pathname && this.props.pathname.length <= 1?"rd-nav-item active":"rd-nav-item"}><Link className="rd-nav-link" to={"/"}>Forsiden</Link>
                      </li>
                      <li className={this.props.pathname && this.props.pathname == '/nye-gratisting/'?"rd-nav-item active":"rd-nav-item"}><Link className="rd-nav-link" to={"/nye-gratisting/"}>Nye</Link>
                      </li>
                      <li className={this.props.pathname && this.props.pathname == '/populare-gratisting/'?"rd-nav-item active":"rd-nav-item"}><Link className="rd-nav-link" to={"/populare-gratisting/"}>Populære</Link>
                      </li>
                      <li className={this.props.pathname && this.props.pathname == '/gratisting-med-hoy-verdi/'?"rd-nav-item active":"rd-nav-item"}><Link className="rd-nav-link" to={"/gratisting-med-hoy-verdi/"}>Verdifulle</Link>
                      </li>
                      <li className={this.props.pathname && this.props.pathname == '/kategorier/'?"rd-nav-item active":"rd-nav-item"}><Link className="rd-nav-link" to={"/kategorier/"}>Kategorier</Link></li>
                      {/*
                      <li className="rd-nav-item"><a className="rd-nav-link">Pages</a>
                        <ul className="rd-menu rd-navbar-dropdown">
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link" href="404.html">404</a>
                          </li>
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link"
                                                              href="typography.html">Typography</a>
                          </li>
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link" href="buttons.html">Buttons</a>
                          </li>
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link" href="forms.html">Forms</a>
                          </li>
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link"
                                                              href="tabs-and-accordions.html">Tabs and accordions</a>
                          </li>
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link" href="progress-bars.html">Progress
                            bars</a>
                          </li>
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link" href="tables.html">Tables</a>
                          </li>
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link" href="grid-system.html">Grid
                            system</a>
                          </li>
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link" href="privacy-policy.html">Privacy
                            policy</a>
                          </li>
                          <li className="rd-dropdown-item"><a className="rd-dropdown-link" href="search-results.html">Search
                            results</a>
                          </li>
                        </ul>
                      </li>
                      <li className="rd-nav-item"><a className="rd-nav-link" href="contacts.html">Contacts</a>
                      </li>*/}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
      </>
    )
  }
}

export default Header
