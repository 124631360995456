/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import "../scss/bootstrap/bootstrap.scss"
import "../scss/fonts/fonts.scss"
import "../scss/custom/style.scss"

const Layout = ({ children}) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }

      img1: file(relativePath: { eq: "ie8-panel/warning_bar_0000_us.jpg" }) {
        childImageSharp {
          fixed(width: 42, height: 820) {
            src
          }
        }
      }
    }
  `)

  return (
    <>
      <div>
        {/*<div className="ie-panel"><a href="http://windows.microsoft.com/en-US/internet-explorer/"><img src={data.img1.childImageSharp.fixed.src} height={42} width={820} alt="You are using an outdated browser. For a faster, safer browsing experience, upgrade for free today." /></a></div>*/}
        {/*<div className="preloader">
          <div className="preloader-body">
            <div className="cssload-container">
              <div className="cssload-speeding-wheel" />
            </div>
          </div>
        </div>*/}
        <div className="page">
          <Header siteTitle={"Gratis.no"}/>

          <main>{children}</main>

        </div>

        <Footer/>
       </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
