import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logo-default-658x114.png" }) {
          childImageSharp {
            fixed(width: 329, height: 57) {
              src
            }
          }
        }
        logo2: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fixed(width: 216, height: 64) {
              src
            }
          }
        }
        logo3: file(relativePath: { eq: "logo3.png" }) {
          childImageSharp {
            fixed(width: 221, height: 59) {
              src
            }
          }
        }
        logo4: file(relativePath: { eq: "logo4.png" }) {
          childImageSharp {
            fixed(width: 237, height: 69) {
              src
            }
          }
        }
        logo5: file(relativePath: { eq: "logo5.png" }) {
          childImageSharp {
            fixed(width: 237, height: 69) {
              src
            }
          }
        }
      logo6: file(relativePath: { eq: "logo6.png" }) {
          childImageSharp {
            fixed(width: 237, height: 69) {
              src
            }
          }
        }
      }`}
    render={data => (
      <div className="rd-navbar-brand">
        <a className="brand" href="/"><img alt="Gratis.no logo" src={data.logo6.childImageSharp.fixed.src} width={329} height={59}/></a>
      </div>
    )}
  />
)
