import { graphql, Link,useStaticQuery } from "gatsby"
import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Footer = () => {

  const data = useStaticQuery(graphql`
    query {
         popTags: allSanityTag {
              totalCount
              edges {
                node {
                  tag
                  slug {
                    current
                  }
                }
              }
            }
            
        allOffers: allSanityOffer(filter:{isDeleted: {eq: false}}){
          edges {
            node {
              slug {
                current
              }
              tags{
                tag
                slug{
                  current
                }
              }
            }
          }
        }
        
        bgImage:  file(relativePath: { eq: "home-01-1920x570.jpg" }) {
          childImageSharp {
            fixed(width: 1920, height: 570) {
              src
            }
          }
        }
        
        h1Image:  file(relativePath: { eq: "banner-arrow-184x147.png" }) {
          childImageSharp {
            fixed(width: 184, height: 147) {
              src
            }
          }
        }
    
        img1: file(relativePath: { eq: "page1_pic2-270x271.jpg" }) {
          childImageSharp {
            fixed(width: 270, height: 271) {
              src
            }
          }
        }
    }
    `)

  let tagData = [];
  let tagsInUse = [];
  let usedSlugs = [];
  let usedTags = [];

  data.allOffers.edges.forEach(itm => {
    itm.node.tags.forEach(itm2 => {
      tagsInUse.push(trimJunkChars(itm2.slug.current.trim()))
      tagData.push({'slug': trimJunkChars(itm2.slug.current).trim(),'tag': itm2.tag.trim()})
    })
  });

  tagData.sort(compareTagObjects);

  let popTags = tagData

  shuffle(popTags)


  return(
      <div>
        <footer className="footer novi-bg novi-bg-img">
          <div className="footer-content">
            <div className="container">
              <div className="row row-30">
                <div className="col-lg-3 col-sm-6">
                  <h4 className="footer-title">Innhold</h4>
                  <ul className="footer-list list-xs">
                    <li><Link className="footer-link" to="/populare-gratisting/">Populære gratisting</Link></li>
                    <li><Link className="footer-link" to="/nye-gratisting/">Nye gratisting</Link></li>
                    <li><Link className="footer-link" to="/gratisting-med-hoy-verdi/">Verdifulle gratisting</Link></li>
                    <li><Link className="footer-link" to="/kategorier/">Kategorier</Link></li>
                    {/*<li><a className="footer-link" href="#">Price List</a></li>
                    <li><a className="footer-link" href="#">Contacts</a></li>
                    <li><a className="footer-link" href="#">Registration</a></li>
                    <li><a className="footer-link" href="#">News</a></li>*/}
                  </ul>
                </div>
                <div className="col-lg-3 col-sm-6">
                  <h4 className="footer-title">Sosiale medier</h4>
                  <ul className="footer-list list-xs">
                    <li><OutboundLink className="footer-link" href="#">Facebook</OutboundLink></li>
                    <li><OutboundLink className="footer-link" href="#">Instagram</OutboundLink></li>
                    {/*<li><a className="footer-link" href="#">Testimonials</a></li>
                    <li><a className="footer-link" href="#">Account Refill</a></li>
                    <li><a className="footer-link" href="#">Payments</a></li>*/}
                  </ul>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <h4 className="footer-title">Utvalgte kategorier</h4>
                  {/*<ul className="footer-list list-xs">
                    <li><a className="footer-link" href="#">iStep</a></li>
                    <li><a className="footer-link" href="#">Artex</a></li>
                    <li><a className="footer-link" href="#">Style Shop</a></li>
                    <li><a className="footer-link" href="#">CrystalTech</a></li>
                    <li><a className="footer-link" href="#">VIP Fashion</a></li>
                  </ul>*/}
                  <br/>
                  {popTags.map((tag,i) => {
                    if(tag && (!usedSlugs.includes((tag.slug))) && i < 20){
                      return(
                        <span style={{display:'inline-block',fontSize:'120%'}} key={i}>
                          {i >= 0 && <>&nbsp;⬤&nbsp;</>}
                          <Link to={"/"+tag.slug+"/"}>
                          {trimJunkChars(tag.tag).charAt(0).toUpperCase() + trimJunkChars(tag.tag).slice(1)}
                        </Link>&nbsp;&nbsp;</span>
                      )
                    }
                  })}
                </div>
                {/*<div className="col-lg-3 col-sm-6">
                  <h4 className="footer-title">Facebook</h4>
                  <div className="fw_facebook">
                    <div className="fb-like-box fb_iframe_widget" id="fb-like-box" data-show-border="false" data-stream="false" data-header="false" data-show-faces="true" data-colorscheme="light" data-href="https://www.facebook.com/TemplateMonster" data-width="270px" />
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
          <div className="footer-panel">
            <div className="container">
              <p className="rights">
                <span>©&nbsp; </span><span className="copyright-year" />{/*new Date().getFullYear()*/}<span>&nbsp;</span><span><Link to="/om-gratis.no/">Gratis.no</Link></span><span>.&nbsp;</span><Link to="/personvern/">Personvern</Link><span>.&nbsp;</span><Link to="/brukerbetingelser/">Bruksvilkår</Link>.<span>&nbsp;</span><Link to="/avmelding/">Avmelding</Link>.
                {/*<span style={{float:'right'}}><a href="/om-gratis.no">Om Gratis.no</a></span>*/}
              </p>
            </div>
          </div>
        </footer>
        <div className="snackbars" id="form-output-global" />
      </div>
  )
}

export default Footer


function shuffle(array) {
  let currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

function trimJunkChars(str){
  //console.log('first char: '+str.charAt(0)+ " ("+str+")");
  /*if(str.charAt(0) == " "){
    //console.log("AHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHHH");
    str = str.substring(1);
  }*/

  str = (str.replace(/[^0-9a-zA-ZøæåÆØÅ-\s]/,"")).trim()
  return str
}

// https://stackoverflow.com/questions/25270864/what-is-last-num-refers-to-in-the-array-sort-function
function compareTagObjects( a, b ) {
  if ( a.tag < b.tag ){
    return -1;
  }
  if ( a.tag > b.tag ){
    return 1;
  }
  return 0;
}
